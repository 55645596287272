<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.stall_booked_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type"
                    >
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.stall_category') }}
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" v-if="search.circular_type !== 2">
                    <b-form-group
                      label-for="stall_type"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.stall_type') }}
                      </template>
                      <b-form-select
                        plain
                        id="stall_type"
                        :options="stallTypeList"
                        v-model="search.stall_type"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card v-if="finalList.length">
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.stall_booked_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.stall_booked_report') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="4">
              <span>{{ $t('globalTrans.year') }}</span>:
              <strong>{{ parseInt(search.year) ? $n(search.year, {useGrouping: false}) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="4" class="text-center">
              <span>{{ $t('ditfTradeFairManages.circular_type') }}</span>:
              <strong>{{ parseInt(search_param.circular_type) ? getSearchParamName(search_param.circular_type, 'cType') : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="4" class="text-right">
              <span>{{ $t('tradeFairConfig.fair_name') }}</span>:
              <strong>{{ parseInt(search_param.fair_id) ? getSearchFairName(search_param.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <table v-if="parseInt(search_param.circular_type) === 1" style="width:100%;">
                    <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('globalTrans.org_name') }}</th>
                        <th>{{ $t('fair_report.company_mobile') }}</th>
                        <th>{{ $t('fair_report.representative_name_mobile_number') }}</th>
                        <th>{{ $t('tradeFairConfig.stall_type') }}</th>
                        <th>{{ $t('ditfConfig.stall_no') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="finalList.length">
                      <span hidden>{{ $si = 1 }}</span>
                      <template v-for="(items, index) in finalList">
                        <tr style="background: #ddd;" :key="index">
                          <td colspan="7" class="text-left">{{ $t('tradeFairConfig.stall_category') }} : <strong>{{ currentLocale === 'bn' ? items.cat_name_bn : items.cat_name_en }}</strong> </td>
                        </tr>
                        <tr v-for="(item, index1) in items.assigned_stalls" :key="index1+index">
                          <td class="text-center"> {{$n($si++)}}</td>
                          <td>{{ currentLocale === 'bn' ? item.booked_stall?.app_main?.comp_name_bn : item.booked_stall?.app_main?.comp_name_en }}</td>
                          <td>{{ item.booked_stall?.app_main?.mobile_no | mobileNumber }}</td>
                          <td>
                            <div v-for="(itm, indx) in item.booked_stall?.app_main?.officers" :key="indx+index1+index">
                              <span>{{ (currentLocale === 'bn' ? itm.name_bn : itm.name_en)+ ' - ' + itm.mobile_no }}</span>
                              <span v-if="indx+1 < item.booked_stall?.app_main?.officers.length">{{ ', '}}</span>
                            </div>
                          </td>
                          <td>{{ getStallType(items.stall_type) }}</td>
                          <td>
                            {{ $n(item.stall_no) }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                    </tbody>
                  </table>
                  <table v-if="parseInt(search_param.circular_type) === 2" style="width:100%;">
                    <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('globalTrans.org_name') }}</th>
                        <th>{{ $t('fair_report.company_mobile') }}</th>
                        <th>{{ $t('fair_report.representative_name_mobile_number') }}</th>
                        <th>{{ $t('fair_report.stall_name') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="finalList.length">
                      <span hidden>{{ $si = 1 }}</span>
                        <tr v-for="(item, index) in finalList" :key="index">
                          <td class="text-center"> {{$n($si++)}}</td>
                          <td>{{ currentLocale === 'bn' ? item.other_booked?.app_main?.comp_name_bn : item.other_booked?.app_main?.comp_name_en }}</td>
                          <td>{{ item.other_booked?.app_main?.mobile_no | mobileNumber }}</td>
                          <td>
                            <div v-for="(itm, indx) in item.other_booked?.app_main?.officers" :key="indx+index">
                              <span>{{ (currentLocale === 'bn' ? itm.name_bn : itm.name_en)+ ' - ' + itm.mobile_no }}</span>
                              <span v-if="indx+1 < item.other_booked?.app_main?.officers.length">{{ ', '}}</span>
                            </div>
                          </td>
                          <td>{{ currentLocale === 'bn' ? item.cat_name_bn : item.cat_name_en }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="5" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfStallBookedReport } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    this.search.year = currentYear
    // this.loadData()
  },
  data () {
    return {
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        stall_category: 0,
        stall_type: 0
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0,
        stall_category: 0,
        stall_type: 0
      },
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    circularType (fair) {
      const sectorObj = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.find(el => el.value === parseInt(fair))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfStallBookedReport, this.search)
      if (result.success) {
        this.search_param = result.search_data
        this.finalList = result.data
        this.isResult = false
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getCompanyTypeName (id) {
      const companyType = this.$store.state.TradeFairService.commonObj.companyTypeList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getStallType (id) {
      const stallType = this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.find(obj => obj.value === parseInt(id))
      if (typeof stallType !== 'undefined') {
        return this.$i18n.locale === 'en' ? stallType.text_en : stallType.text_bn
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, year: this.search_param.year, circular_type: this.search_param.circular_type, fair_id: this.search_param.fair_id, stall_category: this.search_param.stall_category, stall_type: this.search_param.stall_type })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfStallBookedReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
