// All Itf Report Apis......
const itfReport = '/itf-report/'
// Exhibit Info Report
export const exhibitInfoReport = itfReport + 'exhibit-info-report/'
export const exhibitInfoReportList = exhibitInfoReport + 'list'
export const exhibitInfoReportShow = exhibitInfoReport + 'show'

export const fairCalanderInfoReport = itfReport + 'fair-calander-info-report/'
export const fairCalanderInfoReportList = fairCalanderInfoReport + 'list'

// Ledger Report
export const fairLedgerRepostInfo = itfReport + 'fair-ledger-report/'
export const fairLedgerReportList = fairLedgerRepostInfo + 'list'

// Application Report
export const applicationReport = itfReport + 'application-report/'
export const applicationReportList = applicationReport + 'list'
export const applicationReportShow = applicationReport + 'show'

// All DITF Report Apis......
const ditfReport = '/ditf-report/'

// DITF Application Report
export const ditfApplicationReport = ditfReport + 'application-report/list'

// DITF stall Booked Report
export const ditfStallBookedReport = ditfReport + 'stall-book-report/list'

// DITF stall Wise Bidding Report
export const ditfStallWiseBiddingReport = ditfReport + 'stall-wise-bidding-report/list'

// Ledger Report
export const ditfLedgerReportInfo = ditfReport + 'applicant-ledger-report/list'
